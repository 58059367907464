import {
  ROOT,
  CREATE_BOOKING,
  GET_DETAIL_BOOKING,
  UPDATE_BOOKING,
  CANCEL_BOOKING,
  ONCAR_BOOKING,
  GET_LIST_BOOKING,
  ADD_PAYMENT_BOOKING,
} from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function createBooking(data) {
  return fetch(ROOT + CREATE_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function cancelBooking(data) {
  return fetch(ROOT + CANCEL_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function addPaymentBooking(data) {
  return fetch(ROOT + ADD_PAYMENT_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function onCarBooking(data) {
  return fetch(ROOT + ONCAR_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getDetailBooking(data) {
  return fetch(ROOT + GET_DETAIL_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function updateBooking(data) {
  return fetch(ROOT + UPDATE_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getListBooking(data) {
  return fetch(ROOT + GET_LIST_BOOKING, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const booking = {
  createBooking,
  cancelBooking,
  onCarBooking,
  addPaymentBooking,
  updateBooking,
  getDetailBooking,
  getListBooking,
};
