import { booking } from "@/api/booking.js";
import dayjs from "dayjs";
export default {
  components: {},
  data: () => ({
    listBooking: [],
    isLoading: false,
    listStatus: [
      {
        id: "CANCEL",
        title: "Đã huỷ",
      },
      {
        id: "ONCAR",
        title: "Đã đón",
      },
      {
        id: "BOOKED",
        title: "Đã đặt",
      },
      {
        id: "COMPLETED",
        title: "Hoàn thành",
      },
    ],
  }),
  created() {
    this.getListBooking();
  },
  methods: {
    async getListBooking() {
      await booking.getListBooking().then((res) => {
        this.listBooking = res;
      });
    },
    async cancelBooking(Id) {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let params = {
        userId: JSON.parse(localStorage.getItem("userData")).id,
        bookingId: Id,
      };

      await booking.cancelBooking(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Huỷ ghế thành công !",
          });
          this.getListBooking();
        }
      });
      this.isLoading = false;
      // Tắt loading
      loading.close();
    },
    convertNameDepartureSchedule(name) {
      return name.replace(
        " - ",
        "<br><i class='bx bx-right-arrow-alt'></i><br>"
      );
    },
    _dayjs: dayjs,
  },
};
